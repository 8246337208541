<template>
  <div class="">
    <Card class="p-m-2">
      <template #title>
        Sms Gönder
      </template>
      <template #content>

        <div class="p-grid">
          <div class="p-col-7 p-md-7 p-lg-7 ">
            <div class="p-grid p-fluid p-formgrid">
              <div class="p-field p-col-12">
                <span for="spanSmsSablon">SMS Şablon</span>
                <Dropdown id="drpSmsSablon" v-model="SelectedSmsSablon" :options="SmsSablonListesi" optionLabel="Ad"
                  optionValue="SmsSablon_rowid" placeholder="Seçiniz..." @change="SmsSablonChange" />
              </div>
              <div class="p-field p-col-12">
                <span for="Icerik">İçerik</span>
                <Textarea readonly="true" v-model="Icerik" rows="2" cols="30" />
              </div>
              <div class="p-field p-col-12">
                <p><b>Yazı uzunluğu</b> : {{ this.IcerikUzunluk }}</p>
                <p style="font-size: 12px;">150 karakterden fazla olan mesajlarınızda gönderim ucreti değişebilir.
                  Öğrenmek için fiyatlandırmalara bakınız</p>
              </div>
              <div class="p-field p-col-12 p-d-flex p-ai-end">
                <Button :disabled="button_disable" label="Gönder" icon="pi pi-send" class="p-button-sm p-button-danger"
                  @click="this.Gonder()" />
              </div>
            </div>

          </div>

          <div class="p-col-5 p-md-5 p-lg-5 ">
            <DataTable_Custom v-if="DataTable_render" :columns="columns" :data="MukellefListesi"
              v-on:selected_rows="getSelectedRows" :selectionmode="true" :detailbutton="false" datakey="rowid" />
          </div>
        </div>

      </template>
    </Card>
  </div>
</template>

<script>
import GlobalServis from "../../../services/GlobalServis";
import DataTable_Custom from "../DataTable/DataTable.vue";
export default {
  data() {
    return {
      DataTable_render: true,
      button_disable: false,
      MukellefListesi: [],
      selectedrows: [],
      columns: [
        { field: "Unvan", header: "Ünvan" },
        { field: "CepTelefon", header: "Cep Telefon" },
      ],

      Mesaj_tur: '',
      Icerik: '',
      Konu: '',
      Baslik: '',

      SmsSablonListesi: [],
      SelectedSmsSablon: '',
      IcerikUzunluk: 0,
    };
  },
  methods: {
    Gonder() {
      this.button_disable = true;
      if (this.selectedrows.length === 0) {
        this.$toast.add({
          severity: "warn",
          summary: "Uyarı",
          detail: "Alıcı seçin.",
          life: 3000,
        });

        this.button_disable = false;
      }
      else {
        this.selectedrows.forEach(element => {
          setInterval(() => { }, 500);
          let data = {
            Mesaj_tur: 'SablonSMS',
            Kime: element.CepTelefon,
            SmsSablon_rowid: this.SelectedSmsSablon,
          }

          GlobalServis.GlobalServis("POST", "MesajGonder", data).then(
            (res) => {
              if (res.status == 200) {

                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Sms gönderim isteği iletildi.",
                  life: 3000,
                });
              } else if (res.status == 400) {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Önemli bir hata meydana geldi",
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Bilinmeyen bir hata meydana geldi",
                  life: 3000,
                });
              }
            }
          );

        })


        setInterval(() => {
          this.button_disable = false;
        }, 5000);

      }
    },
    getSelectedRows(SelectedRows) {
      this.selectedrows = SelectedRows;
    },
    SmsSablonChange(e) {
      this.Icerik = this.SmsSablonListesi.find((element) => element.SmsSablon_rowid == e.value).Icerik;
      this.IcerikUzunluk = this.Icerik.length;
    },
    InitServis() {
      this._GetBildirimTablosu();
      this._SmsSablonTablosu();
    },
    _GetBildirimTablosu() {
      let data = {
        Tip: 'SMS'
      }
      GlobalServis.GlobalServis("POST", "GetMukellefIletiInfo", data).then(
        (res) => {
          if (res.status == 200) {
            this.MukellefListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      )
    },
    _SmsSablonTablosu() {
      GlobalServis.GlobalServis("GET", "GetSmsSablonTablosu", "").then(
        (res) => {
          if (res.status == 200) {
            this.SmsSablonListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      )
    }
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable_Custom
  },
};
</script>

<style scoped></style>