<template>
  <div>
    <DataTable :value="data" v-model:filters="filtre" :globalFilterFields="FilterFilelds" responsiveLayout="scroll"
      :scrollable="true" class="p-datatable-sm" :paginator="true" :rows="10" :rowsPerPageOptions="[10, 20, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" :dataKey="datakey" resizableColumns
      columnResizeMode="fit" ref="dataTable" showGridlines stripedRows sortMode="multiple" removableSort
      v-model:selection.sync="selectedrows" @row-select="onRowSelect" @row-unselect="onRowUnselect">
      <!--columnResizeMode="expand" -->
      <template #empty> Veri Bulunamadı. </template>
      <template #loading> Yükleniyor. Lüften Bekleyin. </template>
      <template #header>
        <div class="p-grid">
          <div class="p-text-left p-col-6">
            <Button icon="pi pi-external-link" label="Export" class="p-button-raised p-button-rounded p-button-sm"
              @click="exportCSV($event)" />
          </div>
          <div class="p-text-right p-col-6">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="filtre['global'].value" placeholder="Ara" />
            </span>
          </div>
        </div>
      </template>

      <Column v-if="selectionmode" :frozen="true" selectionMode="multiple" :exportable="false"
        style="max-width: 2.5rem;">
      </Column>

      <Column sortable v-for="col of columns" :field="col.field" :header="col.header" :key="col.RowId">
      
      </Column>

      <Column v-if="detailbutton" :exportable="false" style="max-width: 4rem">
        <template #body="slotProps">
          <Button icon="pi pi-pencil" class="p-button-success" @click="edit(slotProps)" />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";

export default {
  props: {
    columns: Array,
    data: Array,
    selectionmode: Boolean,
    datakey: String,
    detailbutton: { default: true, type: Boolean },
  },
  data() {
    return {
      filtre: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      FilterFilelds: [],
      selectedrows: []
    };
  },
  methods: {
    exportCSV() {
      this.$refs.dataTable.exportCSV();
    },
    edit(EditRowData) {
      this.$emit("set-child-data", EditRowData);
    },
    onRowSelect() {
      this.$emit("selected_rows", this.selectedrows);
    },
    onRowUnselect() {
      this.$emit("selected_rows", this.selectedrows);
    }
  },
  mounted() {
    for (let index = 0; index < this.columns.length; index++) {
      this.FilterFilelds.push(this.columns[index].field);
    }
    window.$('.p-datatable-thead').css("z-index", "0");
    this.$emit("selected_rows", this.selectedrows);
  },
  created() { },
};
</script>
